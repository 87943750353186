.button{

    padding: 10px 20px;
    background-color: #FF9800;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    margin-top:3%;
    
}

.details-section{

    margin-top: 6%;
    background: #8444871f;
}

.section-heading {
    padding-top: 7%;
}

.blockcls {
    border: 1px solid #7e3e8217;
    background: #84458769;
    margin: 20px;
}



.profile-dropdown {
    position: absolute;
    top: 10px;
    right: 60px;
}

.profile-button {
    background-color: transparent;
    border: none;
    cursor: pointer;
    color: #f3ab00;
    font-weight: bold;
}

.dropdown-content {
    display: block;
    position: absolute;
    background-color: #f9f9f9;
    /* min-width: 160px; */
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
}

.dropdown-content a {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
}

.dropdown-content a:hover {
    background-color: #f1f1f1;
}
